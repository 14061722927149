




























































































import {
  Vue,
  Component,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import ContractSavingsStartKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/start/contract-savings-start-kuspit-view-model';
import {
  PersonEntity,
} from '@/modules/my-investment/person/domain/entities/person-entity';

@Component({ name: 'ContractSavingsStartKuspit' })
export default class ContractSavingsStartKuspit extends Vue {
  @PropSync('enableNext', { type: Boolean })
  synced_enable_next!: boolean;

  contract_savings_start_kuspit_view_model = new ContractSavingsStartKuspitViewModel();

  @Watch('contract_savings_start_kuspit_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loading', is_loading);
    this.synced_enable_next = !is_loading;
  }

  @Watch('contract_savings_start_kuspit_view_model.person')
  onPersonChange(person: PersonEntity) {
    if (!this.contract_savings_start_kuspit_view_model.exists_step) {
      this.contract_savings_start_kuspit_view_model.setInputsValues(person);
    }
  }

  async created() {
    await this.contract_savings_start_kuspit_view_model.initialize();
    this.$emit('loading', this.contract_savings_start_kuspit_view_model.is_loading);
  }

  async nextStep() {
    const saved = await this.contract_savings_start_kuspit_view_model.saveStep();
    return saved;
  }
}
