import i18n from '@/vue-app/plugins/i18n';

import TYPES from '@/types';
import { v4 as uuid } from 'uuid';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  PersonEntity,
} from '@/modules/my-investment/person/domain/entities/person-entity';
import Inject from '@/modules/shared/domain/di/inject';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsStartKuspitViewModel {
  readonly i18n_common_namespace = 'components.common.gender';

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  readonly getPersonQuery!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  private readonly GENDERS: Record<string, string> = {
    Female: 'Femenino',
    Male: 'Masculino',
  }

  constructor() {
    this.on_boarding_step.id = uuid();
  }

  i18n_namespace = 'components.contract-savings.start';

  exists_step = false;

  step_name = 'on_boarding_kuspit_personal_information';

  investment_provider_id = '';

  investment_provider_name = 'kuspit';

  is_loading = true;

  inputs = {
    full_name: '',
    curp: '',
    nationality: '',
    birthday: '',
    sex: '',
    rfc: '',
    homoclave: '',
  };

  private on_boarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  get person() {
    return this.getPersonQuery.execute();
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInputsValues = (person: PersonEntity) => {
    this.inputs.full_name = `${person.name} ${person.last_name} ${person.second_last_name}`;
    this.inputs.curp = person.curp;
    this.inputs.nationality = person.nationality.name;
    this.inputs.birthday = person.date_of_birth;
    this.inputs.sex = i18n.tc(`${this.i18n_common_namespace}.${person.gender.name}`);
    this.inputs.rfc = person.rfc.slice(0, -3);
    this.inputs.homoclave = person.rfc.slice(-3, person.rfc.length);
  };

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.full_name = `${this.on_boarding_step.payload.name}
    ${this.on_boarding_step.payload.last_name} ${this.on_boarding_step.payload.second_last_name}`;
    this.inputs.curp = this.on_boarding_step.payload.curp;
    this.inputs.nationality = this.on_boarding_step.payload.nationality;
    this.inputs.birthday = this.on_boarding_step.payload.date_of_birth;
    this.inputs.sex = this.on_boarding_step.payload.gender;
    this.inputs.rfc = this.on_boarding_step.payload.rfc.slice(0, -3);
    this.inputs.rfc = this.on_boarding_step.payload.rfc.slice(
      -3, this.on_boarding_step.payload.rfc.length,
    );
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        name: this.person.name,
        last_name: this.person.last_name,
        second_last_name: this.person.second_last_name,
        gender: this.person.gender.name,
        nationality: this.person.nationality.name,
        date_of_birth: this.person.date_of_birth,
        curp: this.person.curp,
        rfc: this.person.rfc,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    this.verifyStep(steps);

    this.setInputsValues(this.person);
    this.is_loading = false;
  }
}
